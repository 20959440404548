import React, { useState, useEffect } from "react";
import "./MeetTheStars.css";
import CountdownCreators from "../countdown/launch/creator/vtuber/CountdownCreators";

const MeetTheStars = ({ isNavOpen }) => {
  // Add state for banner URL
  const [bannerUrl, setBannerUrl] = useState("");

  // Add effect to handle responsive banner
  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth < 600;
      setBannerUrl(
        isMobile
          ? "https://gamersupps.s3.us-east-2.amazonaws.com/images/vcard/banners/Home-Banner-Bottom-Mobile-min.png"
          : "https://gamersupps.s3.us-east-2.amazonaws.com/images/vcard/banners/Home-Banner-Bottom-min.png"
      );
    };

    // Set initial banner
    handleResize();

    // Add resize listener
    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className={`MeetTheStarsWrapper ${isNavOpen ? "nav-open" : ""}`}>
      <h3 className="MeetTheStarsH3">MEET THE</h3>
      <h1 className="MeetTheStarsH1">STARS</h1>
      <img
        className="divider-img-mts"
        src="https://gamersupps.s3.us-east-2.amazonaws.com/images/Divide-Gold.png"
      />
      <CountdownCreators />
    </div>
  );
};

export default MeetTheStars;
