import React, { useState } from "react";

const NewsletterSection = () => {
  const [status, setStatus] = useState("idle"); // idle, loading, success, error

  const handleSubmit = async (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    setStatus("loading");

    try {
      const response = await fetch(
        "https://cupathon.com/nod/api/add-user-to-list",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email,
            listId: "UNjVDy",
          }),
        }
      );

      if (response.ok) {
        e.target.reset();
        setStatus("success");
        // Reset success message after 3 seconds
        setTimeout(() => setStatus("idle"), 3000);
      } else {
        setStatus("error");
      }
    } catch (error) {
      console.error("Subscription error:", error);
      setStatus("error");
    }
  };

  const getButtonText = () => {
    switch (status) {
      case "loading":
        return "SIGNING UP...";
      case "success":
        return "SIGNED UP!";
      case "error":
        return "TRY AGAIN";
      default:
        return "SIGN ME UP!";
    }
  };

  return (
    <section className="newsletter-section">
      <div className="newsletter-content section-grid">
        <h2 style={{ marginBottom: "0" }}>DON'T MISS OUT!</h2>
        <figure className="newsletter-divider-box">
          <img
            style={{ width: "100%" }}
            src="https://gamersupps.s3.us-east-2.amazonaws.com/Vcards-assets/GS-Home-Divide_slfqdj.webp"
            alt="Divider"
          />
        </figure>
        <p>
          Join our exclusive VCards Insider Crew and be the first to know about
          every exciting update, milestone, and breakthrough—straight to your
          inbox!
        </p>
        <form
          className="newsletter-form"
          style={{ display: "flex", gap: ".5rem", flexDirection: "column" }}
          onSubmit={handleSubmit}
        >
          <div style={{ display: "flex", gap: ".5rem" }}>
            <input
              type="email"
              placeholder="Email Address"
              name="email"
              className="input"
              disabled={status === "loading" || status === "success"}
            />
            <button
              type="submit"
              className="button"
              disabled={status === "loading" || status === "success"}
              style={{
                opacity: status === "loading" || status === "success" ? 0.7 : 1,
                backgroundColor:
                  status === "success"
                    ? "#4CAF50"
                    : status === "error"
                    ? "#f44336"
                    : undefined,
              }}
            >
              {getButtonText()}
            </button>
          </div>
          {status === "error" && (
            <p
              style={{
                color: "#f44336",
                margin: "0.5rem 0 0",
                fontSize: "0.875rem",
              }}
            >
              Something went wrong. Please try again.
            </p>
          )}
          {status === "success" && (
            <p
              style={{
                color: "#4CAF50",
                margin: "0.5rem 0 0",
                fontSize: "0.875rem",
              }}
            >
              Thanks for subscribing! Welcome to the crew!
            </p>
          )}
        </form>
      </div>
    </section>
  );
};

export default NewsletterSection;
