// src/cardInfo/card_types.jsx

export const ALL_CARD_TYPES = "ALL";
//export const SUPPORTS_CARD_TYPE = "SUPPORT";
export const COMMONS_CARD_TYPE = "COMMON";
export const UNCOMMONS_CARD_TYPE = "UNCOMMON";
export const RARES_CARD_TYPE = "RARE";
export const ULTRA_RARES_CARD_TYPE = "ULTRA RARE";
export const EPIC_RARES_TYPE = "EPIC RARE";
export const SECRET_RARES_CARD_TYPE = "SECRET RARE";
export const GOD_RARES_TYPE = "GOD RARE";
export const PROMO_CARD_TYPE = "PROMO";
export const MASCOTS_CARD_TYPE = "MASCOTS";
export const SUPPORT_CARD_TYPE = "SUPPORT";