import React from "react";
import './LearnToPlayPortal.css';

const LearnToPlayPortal = () => {
    return (
        <div className="LearnToPlayPortal">
            <h3 className="LearnToPlayPortalH3">HOW TO PLAY</h3>
            <img src="https://gamersupps.s3.us-east-2.amazonaws.com/images/Divide-Dark.png" className="img-divider-ltpp" alt="Divider" />
            <p className="LearnToPlayPortalDescription">
                Dive into the action and master the game’s fundamentals! Start by playing your first Power Level card, then uncover exciting mechanics that take your gameplay to the next level!
            </p>
            <a className="LearnToPlayPortalButton" href="/#/learn" style={{fontFamily: "Gill Sans Heavy"}}>LEARN MORE</a>
        </div>
    )
}

export default LearnToPlayPortal;
