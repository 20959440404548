// VCardLanding.jsx
import React from "react";
import "./css/Launch.css";
import CardRevealSection from "./CardRevealSection";
import FeaturesSection from "./FeaturesSection";
import GameplaySection from "./GameplaySection";
import LaunchSection from "./LaunchSection";
import PromoSection from "./PromoSection";
import NewsletterSection from "./NewsletterSection";
import Hero from "./Hero";
const VCardLanding = () => {
  return (
    <div>
      <Hero />
      <FeaturesSection />
      <GameplaySection />
      <LaunchSection />
      <PromoSection />
      <CardRevealSection />
      <NewsletterSection />
    </div>
  );
};

export default VCardLanding;
