const Hero = () => (
  <section className="hero">
    <div className="section-grid hero-content">
      <div className="hero-text-box">
        <h1 className="hero-intro">WELCOME TO</h1>
        <div className="hero-logo-box">
          <img
            src="https://gamersupps.s3.us-east-2.amazonaws.com/Vcards-assets/Nav-Logo_ifytmt.webp"
            className="hero-logo"
            alt="VCard Logo"
          />
          <img
            src="https://gamersupps.s3.us-east-2.amazonaws.com/Vcards-assets/Small-Divide-Gold_yzlfnx.webp"
            alt="Divider"
            className="hero-divider"
          />
        </div>
        <p className="hero-text">
          Get ready for the ultimate entertainment trading card game, where the
          internet's biggest Tubers and iconic Content Creators come to life!
          Featuring over 50 of your favorite online idols, it's a star-studded
          showdown like no other!
        </p>
        <button className="button" style={{ marginTop: "1rem" }}>
          <a
            href="https://gamersupps.gg/pages/vcard"
            target="_blank"
            rel="noreferrer"
            style={{ color: "#000", textDecoration: "none" }}
          >
            CLAIM YOUR FREE CARD!
          </a>
        </button>
      </div>
      <figure className="hero-img-box">
        <img
          src="https://gamersupps.s3.us-east-2.amazonaws.com/Vcards-assets/Main-Cards_tr77c0.webp"
          alt="Product Preview"
        />
      </figure>
    </div>
  </section>
);

export default Hero;
