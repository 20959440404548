import "./css/FeaturesSection.css";

const FeaturesSection = () => (
  <section className="features-section">
    <div className="section-grid features-content">
      {/* Text Content Side */}
      <div className="features-text-box">
        <h2 className="features-title">FEATURING YOUR FAVORITES</h2>
        <img
          src="https://gamersupps.s3.us-east-2.amazonaws.com/Vcards-assets/Small-Divide-Gold_yzlfnx.webp"
          alt="Divider"
          className="divider"
        />
        <p className="features-description">
          Craft your ultimate deck with a dazzling array of unique cards, each
          spotlighting one of over 50 iconic VTubers and beloved creators!
          Unleash your imagination and collect fan-favorite personalities in a
          thrilling card-building adventure like no other
        </p>
      </div>
      {/* Characters Side */}
      <div className="features-characters">
        <img
          src="https://gamersupps.s3.us-east-2.amazonaws.com/Vcards-assets/Vtuber-Lineup_rbeylc.webp"
          alt="Featured VTuber Characters"
          className="features-characters-img"
        />
      </div>
    </div>
  </section>
);

export default FeaturesSection;
