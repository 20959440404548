// src/CardGallery.jsx

import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  ALL_CARD_TYPES,
  UNCOMMONS_CARD_TYPE,
  RARES_CARD_TYPE,
  ULTRA_RARES_CARD_TYPE,
  SECRET_RARES_CARD_TYPE,
  GOD_RARES_TYPE,
  PROMO_CARD_TYPE,
  MASCOTS_CARD_TYPE,
  SUPPORT_CARD_TYPE,
} from "../../cardInfo/card_types";

import "./css/CardGallery.css";
import { preloadImages, isImageCached } from "../../utils/imagePreloader";
import { cardAPI, CardType } from "../../services/api";

const PLACEHOLDER_IMAGE =
  "https://gamersupps.s3.us-east-2.amazonaws.com/images/vcard/cards-sorted/Promo/Placeholder-Web.png";

const CardGallery = ({ appScrollRef, isNavOpen }) => {
  const [selectedCardType, setSelectedCardType] = useState(MASCOTS_CARD_TYPE);
  const [visibleCards, setVisibleCards] = useState([]);
  const [page, setPage] = useState(0);
  const [showScrollButton, setShowScrollButton] = useState(false); // State for scroll button visibility
  const loaderRef = useRef(null);
  const wrapperRef = useRef(null);
  const CARDS_PER_PAGE = 10;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);

  const [fullSetSize, setFullSetSize] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const isLoadingRef = useRef(false); // Added ref to track loading state

  const handleCardTypeClick = (cardType) => {
    setSelectedCardType(cardType);
    setPage(0); // Reset page
    setVisibleCards([]); // Reset visible cards
  };

  const getActiveCards = useCallback(async () => {
    let packName;
    switch (selectedCardType) {
      case MASCOTS_CARD_TYPE:
        packName = "MASCOTS";
        break;
      case UNCOMMONS_CARD_TYPE:
        packName = "UNCOMMONS";
        break;
      case RARES_CARD_TYPE:
        packName = "RARES";
        break;
      case ULTRA_RARES_CARD_TYPE:
        packName = "ULTRA_RARES";
        break;
      case SUPPORT_CARD_TYPE:
        packName = "SUPPORTS";
        break;
      case SECRET_RARES_CARD_TYPE:
        packName = "SECRET_RARES";
        break;
      case GOD_RARES_TYPE:
        packName = "GOD_RARES";
        break;
      case PROMO_CARD_TYPE:
        packName = "PROMOS";
        break;
      case ALL_CARD_TYPES:
      default:
        packName = "ALL";
    }

    const MAX_RETRIES = 3;
    let retryCount = 0;

    while (retryCount < MAX_RETRIES) {
      try {
        const response = await cardAPI.getCardSet(packName);

        if (process.env.NODE_ENV === "development") {
          console.log("[CardGallery] Card set response:", {
            response,
            attempt: retryCount + 1,
          });
        }

        // If we got a valid response with the expected fullSetSize
        if (
          response &&
          (response.fullSetSize > 0 || response.cards.length > 0)
        ) {
          setFullSetSize(response.fullSetSize || 0);
          return response.cards.map((card) => {
            if (!card.cardFrontUrl || card.isPlaceholder) {
              return PLACEHOLDER_IMAGE;
            }
            return card.cardFrontUrl || card;
          });
        }

        // If we got an empty response, wait before retrying
        await new Promise((resolve) => setTimeout(resolve, 1000));
        retryCount++;
      } catch (error) {
        console.error(
          `Error fetching cards (attempt ${retryCount + 1}):`,
          error
        );
        if (retryCount === MAX_RETRIES - 1) {
          return [];
        }
        await new Promise((resolve) => setTimeout(resolve, 1000));
        retryCount++;
      }
    }

    return []; // Return empty array if all retries failed
  }, [selectedCardType]);

  const loadMoreCards = useCallback(async () => {
    if (isLoadingRef.current) return; // Use ref
    isLoadingRef.current = true; // Update ref
    setIsLoading(true); // Update state

    try {
      const activeCards = await getActiveCards();

      // Don't load more cards if we're viewing promos
      if (selectedCardType === PROMO_CARD_TYPE) {
        setVisibleCards([activeCards[0]]);
        return;
      }

      const startIndex = page * CARDS_PER_PAGE;
      const endIndex = Math.min(
        startIndex + CARDS_PER_PAGE,
        activeCards.length
      );

      // Don't load more if we're at the end
      if (startIndex >= activeCards.length) {
        return;
      }

      const newCards = activeCards.slice(startIndex, endIndex);
      setVisibleCards((prev) => [...prev, ...newCards]);
      setPage((prevPage) => prevPage + 1);
    } catch (error) {
      console.error("Error loading more cards:", error);
    } finally {
      isLoadingRef.current = false; // Reset ref
      setIsLoading(false); // Reset state
    }
  }, [selectedCardType, getActiveCards]); // Removed 'isLoading' from dependencies

  useEffect(() => {
    if (selectedCardType === PROMO_CARD_TYPE) {
      getActiveCards().then((cards) => {
        if (cards.length > 0) {
          setVisibleCards([cards[0]]);
        }
      });
      return;
    }

    // Reset and load first batch of cards
    setVisibleCards([]);
    loadMoreCards();
  }, [selectedCardType, loadMoreCards, getActiveCards]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        // Only trigger loadMoreCards when element is intersecting and we're not already loading
        if (entries[0].isIntersecting && !isLoadingRef.current) {
          // Use ref
          loadMoreCards();
        }
      },
      { threshold: 0.5 }
    );

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current);
      }
    };
  }, [loaderRef, loadMoreCards]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 300) {
        // Adjust the value as needed
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };

    // Listen for window scroll events
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const preloadCardImages = async () => {
      try {
        const activeCards = await getActiveCards();
        const cardsToLoad = activeCards
          .slice(0, CARDS_PER_PAGE * 2)
          .filter((url) => typeof url === "string" && url);

        await preloadImages(cardsToLoad);
      } catch (error) {
        console.error("Error preloading images:", error);
      }
    };

    preloadCardImages();
  }, [selectedCardType, getActiveCards, CARDS_PER_PAGE]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleCardClick = (card) => {
    console.log("Card clicked:", card);
    const cardData = typeof card === "string" ? { cardFrontUrl: card } : card;
    setSelectedCard(cardData);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedCard(null);
  };

  const renderCards = useCallback(() => {
    if (isLoading && visibleCards.length === 0) {
      return (
        <div className="loading-spinner-container">
          <div>
            <div className="loading-spinner"></div>
            <div className="loading-text">Loading Cards...</div>
          </div>
        </div>
      );
    }

    const totalToShow = fullSetSize;
    const placeholdersNeeded = totalToShow - visibleCards.length;

    return (
      <>
        {visibleCards.map((card, index) => {
          // Handle both string URLs and card objects with different URL properties
          const imageUrl =
            typeof card === "string"
              ? card
              : card.cardFrontUrl || card.imageUrl || PLACEHOLDER_IMAGE;

          return (
            <img
              key={`card-${index}`}
              src={imageUrl}
              alt={`Card ${index}`}
              className={`card-image ${
                isImageCached(imageUrl) ? "loaded" : "loading"
              }`}
              style={{
                margin: "10px",
                maxWidth: "200px",
                cursor: "pointer",
              }}
              onClick={() => handleCardClick(card)}
            />
          );
        })}
        {placeholdersNeeded > 0 &&
          Array.from({ length: placeholdersNeeded }).map((_, index) => (
            <div
              key={`placeholder-${index}`}
              className="card-placeholder"
              style={{
                margin: "10px",
                width: "200px",
                height: "280px",
                backgroundImage: `url(${PLACEHOLDER_IMAGE})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                borderRadius: "10px",
              }}
            />
          ))}
      </>
    );
  }, [fullSetSize, visibleCards, isLoading]);

  return (
    <div
      ref={wrapperRef}
      className="CardGalleryWrapper"
      style={{ marginTop: isNavOpen ? "30vh" : "2rem" }}
    >
      {showScrollButton && (
        <button
          onClick={scrollToTop}
          className="jump-to-top-button"
          aria-label="SJUMP TO TOP"
          title="JUMP TO TOP"
        >
          ▲
        </button>
      )}
      <div className="sticky-header" style={{ textAlign: "center" }}>
        <h3 className="CardGalleryH3">EXPLORE THE</h3>
        <h1 className="CardGalleryH1">CARD GALLERY</h1>
        <img
          src="https://gamersupps.s3.us-east-2.amazonaws.com/images/Divide-Gold.png"
          alt="Divide Gold"
          className="divide-img-gallery"
        />
      </div>
      <div
        className="sticky-buttons card-type-button-container"
        style={{ textAlign: "center" }}
      >
        <button
          className={`card-type-button ${
            selectedCardType === ALL_CARD_TYPES ? "activeCard" : ""
          }`}
          onClick={() => handleCardTypeClick(ALL_CARD_TYPES)}
        >
          {ALL_CARD_TYPES}
        </button>

        {/*
                    <button 
                        className={`card-type-button ${selectedCardType === SUPPORTS_CARD_TYPE ? 'active' : ''}`}
                        onClick={() => handleCardTypeClick(SUPPORTS_CARD_TYPE)}
                    >
                        {SUPPORTS_CARD_TYPE}
                    </button>                
                */}
        <button
          className={`card-type-button ${
            selectedCardType === MASCOTS_CARD_TYPE ? "activeCard" : ""
          }`}
          onClick={() => handleCardTypeClick(MASCOTS_CARD_TYPE)}
        >
          {MASCOTS_CARD_TYPE}
        </button>
        <button
          className={`card-type-button ${
            selectedCardType === UNCOMMONS_CARD_TYPE ? "activeCard" : ""
          }`}
          onClick={() => handleCardTypeClick(UNCOMMONS_CARD_TYPE)}
        >
          {UNCOMMONS_CARD_TYPE}
        </button>
        <button
          className={`card-type-button ${
            selectedCardType === RARES_CARD_TYPE ? "activeCard" : ""
          }`}
          onClick={() => handleCardTypeClick(RARES_CARD_TYPE)}
        >
          {RARES_CARD_TYPE}
        </button>
        <button
          className={`card-type-button ${
            selectedCardType === ULTRA_RARES_CARD_TYPE ? "activeCard" : ""
          }`}
          onClick={() => handleCardTypeClick(ULTRA_RARES_CARD_TYPE)}
        >
          {ULTRA_RARES_CARD_TYPE}
        </button>

        <button
          className={`card-type-button ${
            selectedCardType === GOD_RARES_TYPE ? "activeCard" : ""
          }`}
          onClick={() => handleCardTypeClick(GOD_RARES_TYPE)}
        >
          {GOD_RARES_TYPE}
        </button>
        <button
          className={`card-type-button ${
            selectedCardType === SUPPORT_CARD_TYPE ? "activeCard" : ""
          }`}
          onClick={() => handleCardTypeClick(SUPPORT_CARD_TYPE)}
        >
          {SUPPORT_CARD_TYPE}
        </button>
        <button
          className={`card-type-button ${
            selectedCardType === SECRET_RARES_CARD_TYPE ? "activeCard" : ""
          }`}
          onClick={() => handleCardTypeClick(SECRET_RARES_CARD_TYPE)}
        >
          {SECRET_RARES_CARD_TYPE}
        </button>
        <button
          className={`card-type-button ${
            selectedCardType === PROMO_CARD_TYPE ? "activeCard" : ""
          }`}
          onClick={() => handleCardTypeClick(PROMO_CARD_TYPE)}
        >
          {PROMO_CARD_TYPE}
        </button>
      </div>
      <div className="card-display" style={{ margin: "0 auto" }}>
        {renderCards()}
      </div>
      {isModalOpen && selectedCard && (
        <div className="modalCardGallery" onClick={closeModal}>
          <div
            className="modalCardGallery-content"
            onClick={(e) => e.stopPropagation()}
          >
            <img
              src={
                selectedCard.cardFrontUrl ||
                selectedCard.imageUrl ||
                selectedCard
              }
              alt="Enlarged Card"
              className="enlarged-card-image"
              style={{ transform: "none" }}
            />
            <span className="close" onClick={closeModal}>
              &times;
            </span>
          </div>
        </div>
      )}
      {!isLoading && visibleCards.length < fullSetSize && (
        <div ref={loaderRef} style={{ height: "20px", margin: "10px" }}></div>
      )}
    </div>
  );
};

export default CardGallery;
