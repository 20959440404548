// Cache to track loaded images
const imageCache = new Set();

// Preload a single image using fetch
const preloadSingleImage = async (url) => {
  if (!url) {
    throw new Error("Invalid URL provided");
  }

  if (imageCache.has(url)) {
    return url;
  }

  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Failed to load image: ${url}`);
    }

    imageCache.add(url);
    return url;
  } catch (error) {
    console.warn(`Error preloading image ${url}:`, error);
    throw error;
  }
};

// Preload multiple images
export const preloadImages = async (urls) => {
  if (!Array.isArray(urls)) {
    console.warn("preloadImages expects an array of URLs");
    return [];
  }

  const validUrls = urls.filter(
    (url) => typeof url === "string" && url.length > 0
  );

  const loadPromises = validUrls.map((url) =>
    preloadSingleImage(url).catch((error) => {
      console.warn(`Failed to preload ${url}:`, error);
      return null;
    })
  );

  return Promise.all(loadPromises);
};

// Check if an image is cached
export const isImageCached = (url) => {
  return imageCache.has(url);
};
