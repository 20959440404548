import axios from "axios";

const BASE_URL = "https://v-card.pro-aff.com/api";

// Card Types enum matching backend
export const CardType = {
  MASCOTS: "MASCOTS",
  COMMONS: "COMMONS",
  UNCOMMONS: "UNCOMMONS",
  RARES: "RARES",
  ULTRA_RARES: "ULTRA_RARES",
  SUPPORTS: "SUPPORTS",
  SECRET_RARES: "SECRET_RARES",
  GOD_RARES: "GOD_RARES",
  PROMOS: "PROMOS",
  ALL: "ALL",
};

// Collaborator Types enum matching backend
export const CollaboratorType = {
  CREATOR: "CREATOR",
  VTUBER: "VTUBER",
};

// API response interfaces (add these)
export const CardSetResponse = {
  packName: "",
  cards: [],
  cardType: "",
  fullSetSize: 0,
};

export const CollaboratorResponse = {
  id: 0,
  name: "",
  profilePicture: "",
  cardImage: "",
  type: "",
  releaseDate: "",
  twitter: "",
  youtube: "",
  twitch: "",
  element: "",
};

// API client instance
const apiClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Card-related API calls
export const cardAPI = {
  getCardSet: async (packName) => {
    try {
      const response = await apiClient.get("/cards", {
        params: { packName: packName.toUpperCase() },
      });

      // If we get a successful response but empty cards, still return the data
      if (response.data && response.data.packName) {
        return response.data;
      }

      throw new Error("Invalid card set response structure");
    } catch (error) {
      console.error(`Error fetching ${packName} card set:`, error);

      // If the server returned data despite the error, use it
      if (error.response?.data) {
        return error.response.data;
      }

      // Return empty set with expected structure on error
      return {
        packName: packName.toUpperCase(),
        cards: [],
        cardType: CardType[packName.toUpperCase()] || CardType.ALL,
        fullSetSize: 0,
      };
    }
  },
};

// Collaborator-related API calls
export const collaboratorAPI = {
  getCollaborators: async (type) => {
    try {
      const response = await apiClient.get("/collaborators", {
        params: { type: type.toUpperCase() },
      });

      // Validate response structure
      if (!Array.isArray(response.data)) {
        throw new Error("Invalid collaborators response structure");
      }

      return response.data;
    } catch (error) {
      console.error(`Error fetching ${type} collaborators:`, error);
      return []; // Return empty array on error
    }
  },

  getCollaboratorCount: async (type) => {
    try {
      const response = await apiClient.get("/collaborators/count", {
        params: { type: type.toUpperCase() },
      });

      // Validate response is a number
      if (typeof response.data !== "number") {
        throw new Error("Invalid collaborator count response");
      }

      return response.data;
    } catch (error) {
      console.error(`Error fetching ${type} collaborator count:`, error);
      return 0; // Return 0 on error
    }
  },
};
