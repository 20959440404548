import React, { useEffect, useState } from "react";
import './home-contact.css';

const ContactUs = ({ isNavOpen }) => {
    const [divideImgSrc, setDivideImgSrc] = useState("https://gamersupps.s3.us-east-2.amazonaws.com/images/Small-Divide-Gold.png");
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });
    const [submitStatus, setSubmitStatus] = useState({
        message: '',
        isError: false
    });

    useEffect(() => {
        const updateImageSrc = () => {
            if (window.innerWidth > 768) {
                setDivideImgSrc("https://gamersupps.s3.us-east-2.amazonaws.com/images/Small-Divide-Gold.png");
            } else {
                setDivideImgSrc("https://gamersupps.s3.us-east-2.amazonaws.com/images/Divide-Gold.png");
            }
        };

        // Initial check
        updateImageSrc();

        // Add event listener for window resize
        window.addEventListener('resize', updateImageSrc);

        // Cleanup function to remove event listener when component unmounts
        return () => {
            window.removeEventListener('resize', updateImageSrc);
        };
    }, []);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 768) {
                document.body.classList.remove('no-scroll');
            } else {
                document.body.classList.add('no-scroll');
                window.scrollTo(0, 0); // Scroll to top when no-scroll is added
            }
        };

        // Initial check
        handleResize();

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Cleanup function to remove class and event listener when component unmounts
        return () => {
            document.body.classList.remove('no-scroll');
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitStatus({ message: '', isError: false });

        try {
            const response = await fetch('https://v-card.pro-aff.com/api/mail/send', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    to: 'arthur@gamersupps.gg', // Your target email
                    subject: `Contact Form Submission from ${formData.name}`,
                    text: `Name: ${formData.name}\nEmail: ${formData.email}\nMessage: ${formData.message}`
                })
            });

            if (!response.ok) {
                throw new Error('Failed to send message');
            }

            setSubmitStatus({
                message: 'Message sent successfully!',
                isError: false
            });
            
            // Clear form
            setFormData({
                name: '',
                email: '',
                message: ''
            });

        } catch (error) {
            setSubmitStatus({
                message: 'Failed to send message. Please try again.',
                isError: true
            });
        }
    };

    return (
        <div className={`ContactUsDual ${isNavOpen ? 'nav-open' : ''}`} id="contact">
            <div className="contact-us-content">
                <div className="contact-us-half-left">
                    <div>
                        <h2 className="contact-us-header">CONTACT US</h2>
                        <img className='divide-img-contact' src={divideImgSrc} alt="Divide" />
                        <p className="contact-info">
                            To reach us you can fill out the form or contact us using the info below.
                        </p>
                        <div>
                            <p className="partnership-info">
                                For partnership requests/questions, please see our Partnerships page over at
                            </p>
                            <a href="https://gamersupps.gg/pages/partnership" className="linkClassWhite" target="_blank" rel="noopener noreferrer">Gamer Supps Partnerships</a>
                        </div>
                        <p className="emailClass">Email: <a href="mailto:contact@gamersupps.gg" className="email-link">contact@gamersupps.gg</a></p>
                    </div>
                </div>
                <div className="contact-us-half-right">
                    <form className="contact-form" onSubmit={handleSubmit}>
                        <input
                            className="contact-form-field"
                            placeholder="Name"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            required
                        />
                        <input
                            className="contact-form-field"
                            placeholder="Email Address"
                            name="email"
                            type="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            required
                        />
                        <textarea
                            className="contact-form-field message-field"
                            placeholder="Message"
                            name="message"
                            value={formData.message}
                            onChange={handleInputChange}
                            required
                        ></textarea>    
                        <div className="contact-form-button-wrapper">
                            <button type="submit" className="contact-button-non-portal">
                                SEND MESSAGE
                            </button>
                        </div>
                        {submitStatus.message && (
                            <div className={`submit-status ${submitStatus.isError ? 'error' : 'success'}`}>
                                {submitStatus.message}
                            </div>
                        )}
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ContactUs;
