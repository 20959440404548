// App.js
import React, { useState, useRef, useEffect } from "react";
import axios from "axios"; // Import Axios for HTTP requests
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { scroller } from "react-scroll";
import Navbar from "./components/navigation/Navbar";
import CreatorPage from "./components/creator/CreatorPage";
//import LearnToPlay from "./components/learning/LearnToPlay";
import ContactUs from "./components/home/ContactUs";
import Footer from "./components/navigation/Footer";
import MeetTheStars from "./components/stars/MeetTheStars";
import WelcomeToVCard from "./components/home/WelcomeToVCard";
import RisingStarsPortal from "./components/home/RisingStarsPortal";
import LearnToPlayPortal from "./components/home/LearnToPlayPortal";
import MeetTheStarsPortal from "./components/home/MeetTheStarsPortal";
import CardGallery from "./components/gallery/CardGallery";

import "./App.css";
import ExploreTheProducts from "./components/home/ExploreTheProducts";
import CountdownCreators from "./components/countdown/launch/creator/vtuber/CountdownCreators";
import SupportersPage from "./components/countdown/launch/supporter/SupportersPage";
import Launch from "./components/launch/Launch";
function useScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
}

function App() {
  const appScrollRef = useRef(null);
  const [usePreLander, setUsePreLander] = useState(true); // State definition stays in App
  const [isNavOpen, setIsNavOpen] = useState(false);

  return (
    <Router>
      <ScrollToTop />
      <div ref={appScrollRef} className="App">
        <Navbar onNavToggle={(isOpen) => setIsNavOpen(isOpen)} />
        <Routes>
          <Route
            exact
            path="/"
            element={
              <Home
                isNavOpen={isNavOpen}
                usePreLander={usePreLander} // Pass down usePreLander as prop
              />
            }
          />
          <Route path="/buy" element={<BuyPage />} />
          <Route
            path="/cards"
            element={
              <CardGallery appScrollRef={appScrollRef} isNavOpen={isNavOpen} />
            }
          />
          <Route
            path="/stars"
            element={<MeetTheStars isNavOpen={isNavOpen} />}
          />

          {/* <Route
            path="/learn-to-play"
            element={<LearnToPlay isNavOpen={isNavOpen} />}
          /> */}
          <Route path="/creator/:id" element={<CreatorPage />} />
          <Route
            path="/contact"
            element={<ContactUs isNavOpen={isNavOpen} />}
          />
          <Route
            path="/vtubers"
            element={<MeetTheStars isNavOpen={isNavOpen} />}
          />{" "}
          {/* New route for VTubers */}
          <Route
            path="/supporters"
            element={<SupportersPage isNavOpen={isNavOpen} />}
          />{" "}
          {/* New route for Supporters */}
          <Route path="/launch" element={<Launch />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

function ScrollToTop() {
  useScrollToTop();
  return null;
}

function Home({ isNavOpen, usePreLander }) {
  const location = useLocation();

  React.useEffect(() => {
    if (location.state && location.state.scrollToContact) {
      scroller.scrollTo("contact", {
        smooth: true,
        duration: 500,
      });
    }
  }, [location]);

  return (
    <div className={`home ${isNavOpen ? "nav-open" : ""}`}>
      {usePreLander ? (
        <Launch usePreLander={usePreLander} /> // Pass down to PreLanderHome
      ) : (
        <>
          <WelcomeToVCard isNavOpen={isNavOpen} />
          <ExploreTheProducts />
          <RisingStarsPortal />
          <LearnToPlayPortal />
          <MeetTheStarsPortal />
        </>
      )}
    </div>
  );
}

function BuyPage() {
  return (
    <div className="buy-page">
      <h2>Buy Cards</h2>
      {/* Add content for the Buy page here */}
    </div>
  );
}

export default App;
