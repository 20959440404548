import "./css/PromoSection.css";

const PromoSection = () => (
  <section className="promo-section">
    <div className="section-grid promo-content">
      <div className="promo-text-box">
        <h2 className="promo-title">EXCLUSIVE PROMO CARD!</h2>
        <img
          src="https://gamersupps.s3.us-east-2.amazonaws.com/Vcards-assets/Small-Divide-Gold_yzlfnx.webp"
          alt="Divider"
          className="divider"
        />
        <p className="promo-description">
          Don't miss your shot at history! In partnership with Vtuber Awards, be
          among the elite few to claim this exclusive promo card. Enter now for
          your chance to win this rare, pre-launch treasure-once they're gone,
          they're gone for good!
        </p>
        <a
          href="https://gamersupps.gg/pages/vcard"
          target="_blank"
          rel="noreferrer"
          style={{ color: "#000", textDecoration: "none", textAlign: "left" }}
        >
          <button className="button">CLAIM YOUR FREE CARD!</button>
        </a>
      </div>

      <div className="promo-cards-box">
        <img
          src="https://gamersupps.s3.us-east-2.amazonaws.com/Vcards-assets/Vtuber-Awards-Promo-Cards_uh9vut.webp"
          alt="Exclusive Promo Cards"
          className="promo-cards-img"
        />
      </div>
    </div>
  </section>
);

export default PromoSection;
