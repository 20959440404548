export const sampleCreatorPlaceholder = [
  {
    id: 99,
    name: "TBD",
    profilePicture:
      "https://gamersupps.s3.us-east-2.amazonaws.com/images/Star-Frames-Empty.png",
    isReleased: false,
    packName: "SUPPORTER",
  },
];

export default sampleCreatorPlaceholder;
