import React, { useState, useEffect } from "react";
import './MeetTheStarsPortal.css';

const MeetTheStarsPortal = () => {
    const [imageSrc, setImageSrc] = useState("https://gamersupps.s3.us-east-2.amazonaws.com/images/Small-Divide-Gold.png");

    useEffect(() => {
        const updateImageSrc = () => {
            if (window.innerWidth < 600) {
                setImageSrc("https://gamersupps.s3.us-east-2.amazonaws.com/images/Divide-Gold.png");
            } else {
                setImageSrc("https://gamersupps.s3.us-east-2.amazonaws.com/images/Small-Divide-Gold.png");
            }
        };

        window.addEventListener('resize', updateImageSrc);
        updateImageSrc(); // Initial check

        return () => window.removeEventListener('resize', updateImageSrc);
    }, []);

    return (
        <div className="MeetTheStarsPortal">
            <div className="non-image-content-mtsp">
                <h3 className="MeetTheStarsPortalH3">MEET THE</h3>
                <h1 className="MeetTheStarsPortalH1">STARS</h1>
                <img src={imageSrc} className="divide-img-mtsp" alt="Divide" />
                <a className="MeetTheStarsPortalButton" href="/#/stars" style={{fontFamily: "Gill Sans Heavy"}}>LEARN MORE</a>
            </div>
        </div>
    )
}

export default MeetTheStarsPortal;
