const LaunchSection = () => (
  <section className="launch-section">
    <div className="launch-content section-grid">
      <h2>LAUNCHING: IMMINENTLY!</h2>
      <div className="launch-divider-box">
        <img
          src="https://res.cloudinary.com/dpip08cgh/image/upload/v1733426167/Divide-Dark_q4kfn2.png"
          className="hero-logo"
          alt="Divider"
        />
      </div>
      <p>
        VCard isn't just a trading card game—it's a groundbreaking platform that
        transforms your favorite content creators into a vibrant, collectible
        universe! With stunning artwork and a mission to champion real artists,
        VCard delivers an immersive experience that goes beyond gaming,
        celebrating creativity and fandom like never before.
      </p>
    </div>
  </section>
);

export default LaunchSection;
