import React from 'react';
import { VTUBER_PACK_NAME } from '../../../cardInfo/pack_names';
import { FaTwitter, FaYoutube, FaTwitch } from 'react-icons/fa';

const CalendarCard = ({ creator, flipped, handleFlip }) => {
  const VTUBER_CARD_PACK_BACK_IMAGE_NAME = "Star-Frames-Empty.png";
  const SUPPORTER_CARD_PACK_BACK_IMAGE_NAME = "Star-Frames-Empty.png";

  // Replace hyphens with spaces in creator's name
  const formattedName = creator.name.replace(/-/g, ' ');
  
  return (
    <div
      className={`calendar-card ${flipped ? 'flipped' : ''}`}
      title={!creator.isReleased ? "This card has not been released yet." : ""}
    >
      <div className="calendar-card-inner">
        <div className="calendar-card-front">
          <img
            src={`https://gamersupps.s3.us-east-2.amazonaws.com/images/${creator.packName === VTUBER_PACK_NAME ? VTUBER_CARD_PACK_BACK_IMAGE_NAME : SUPPORTER_CARD_PACK_BACK_IMAGE_NAME}`}
            alt={formattedName}
            className="calendar-card-image"
          />
        </div>
        <div className="calendar-card-back">
          <img
            src={creator.profilePicture}
            alt={formattedName}
            className={`calendar-card-image ${flipped ? "flipped-image" : ""}`}
          />
          <div className="calendar-card-info">
            <h3>{formattedName}</h3>
            <div className="social-media-buttons">
              <a href={creator.twitter} target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
              <a href={creator.youtube} target="_blank" rel="noopener noreferrer"><FaYoutube /></a>
              <a href={creator.twitch} target="_blank" rel="noopener noreferrer"><FaTwitch /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalendarCard;
