import React, { useState, useEffect } from "react";
import './WelcomeToVCard.css';

const WelcomeToVCard = ({ isNavOpen }) => {
    const [imageSrc, setImageSrc] = useState("https://gamersupps.s3.us-east-2.amazonaws.com/images/Small-Divide-Gold.png");
    const [backgroundImage, setBackgroundImage] = useState("https://gamersupps.s3.us-east-2.amazonaws.com/images/vcard/banners/Home-Banner-Header.png");


    useEffect(() => {
        const updateImages = () => {
            if (window.innerWidth < 468) {
                setImageSrc("https://gamersupps.s3.us-east-2.amazonaws.com/images/Divide-Gold.png");
                setBackgroundImage("https://gamersupps.s3.us-east-2.amazonaws.com/images/vcard/banners/Home-Banner-Header-Mobile.png");
            } else {
                setImageSrc("https://gamersupps.s3.us-east-2.amazonaws.com/images/Small-Divide-Gold.png");
                setBackgroundImage("https://gamersupps.s3.us-east-2.amazonaws.com/images/vcard/banners/Home-Banner-Header.png");
            }
        };

        window.addEventListener('resize', updateImages);
        updateImages(); // Initial check

        return () => window.removeEventListener('resize', updateImages);
    }, []);

    return (
        <div 
            className="WelcomeToVCard" 
            style={{ 
                backgroundImage: `url(${backgroundImage})`, 
                marginTop: (isNavOpen && window.innerWidth < 468) ? '-5.3vh' : '0',
                transition: 'margin-top 0.3s ease-in-out'
            }}
        >
            {isNavOpen && console.log("Nav is open")}
            <div className="non-image-content">
                <h3 className="WelcomeToVCardH3" style={{fontFamily: "Gill Sans Heavy", color: "#fbed9f"}}>WELCOME TO</h3>
                <h1 className="WelcomeToVCardH1" style={{fontFamily: "Gill Sans Heavy"}}>VCARD</h1>
                <img 
                    src={imageSrc} 
                    className="divide-img-wtvc" 
                    alt="Divide" 
                />
                <p className="WelcomeToVCardDescription">
                Get ready for the ultimate entertainment trading card game, where the internet's biggest VTubers and iconic Content Creators come to life! Featuring over 50 of your favorite online idols, it's a star-studded showdown like no other!
                </p>
                <a 
                    className="WelcomeToVCardButton" 
                    href="/#/learn" 
                    style={{ fontFamily: "Gill Sans Heavy" }}
                >
                    BUY NOW
                </a>
            </div>
        </div>
    )
}

export default WelcomeToVCard;
