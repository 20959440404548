import React from "react";
import './ExploreTheProducts.css';

const ExploreTheProducts = () => {
    return (
        <div className="ExploreTheProductsPortal">
            <div className="product-card">

                <div className="product-image-container">

                    <img 
                        src="https://gamersupps.s3.us-east-2.amazonaws.com/images/vcard/Product-Shot-001.png" 
                        alt="Product Shot" 
                        className="product-image"
                        onClick={() => window.location.href = 'https://gamersupps.gg'}
                        role="button"
                        tabIndex={0}
                    />

                </div>
                <div className="card-content">
                    <h3 className="ExploreTheProductsPortalH3">EXPLORE THE PRODUCTS</h3>
                    <img 
                        src="https://gamersupps.s3.us-east-2.amazonaws.com/images/Divide-Dark.png" 
                        className="ExploreTheProductsPortalDivider" 
                        alt="Divider" 
                    />
                    <p className="ExploreTheProductsPortalDescription">
                        Prepare to shine with our debut set, Rising Stars! Each booster box contains 28 booster packs, more than enough to kickstart your journey. Plus, don’t miss out on the stunning range of accessories that’ll not doubt leave you wanting more.
                    </p>
                    <a 
                        className="ExploreTheProductsPortalButton" 
                        href="https://gamersupps.gg" 
                        style={{fontFamily: "Gill Sans Heavy"}}
                    >
                        FIND ON GS
                    </a>
                </div>
            </div>
        </div>
    )
}

export default ExploreTheProducts;
