import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Link as ScrollLink, scroller } from "react-scroll";
import CountdownTimer from "../countdown/launch/CountdownTimer";
import "./css/Navbar.css";

const Navbar = ({ onNavToggle }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [showCountdown, setShowCountdown] = useState(true);
  const targetDate = new Date("2025-01-17T00:00:00");
  const [isNavOpen, setIsNavOpen] = useState(false);

  useEffect(() => {
    if (location.state && location.state.scrollToContact) {
      scroller.scrollTo("contact", {
        smooth: true,
        duration: 500,
      });
    }

    const routesToCheck = ["/cards", "/learn", "/buy", "/"];
    setShowCountdown(true);
  }, [location]);

  const toggleNav = () => {
    const newIsNavOpen = !isNavOpen;
    setIsNavOpen(newIsNavOpen);
    onNavToggle(newIsNavOpen);
  };

  const handleLogoClick = () => {
    // Define what should happen when the logo is clicked
    console.log("Logo clicked!");
    navigate("/"); // Example: navigate to the home page
  };

  return (
    <>
      {showCountdown && <CountdownTimer targetDate={targetDate} />}
      <div className="navbar-container">
        <nav
          className={`navbar ${!isNavOpen ? "navbar-closed" : "navbar-open"}`}
        >
          <div
            className="logo"
            onClick={handleLogoClick}
            style={{ cursor: "pointer" }}
          >
            <div
              className="logoWrapper"
              onClick={handleLogoClick}
              style={{ cursor: "pointer" }}
            >
              <img
                src="https://gamersupps.s3.us-east-2.amazonaws.com/images/Nav-Logo.png"
                alt="VCARD Logo"
              />
            </div>
          </div>
          <button className="hamburger" onClick={toggleNav}>
            ☰
          </button>
          <div className={`nav-links ${isNavOpen ? "open" : ""}`}>
            <Link to="/" className={location.pathname === "/" ? "active" : ""}>
              HOME
            </Link>
            <Link
              to="/cards"
              className={location.pathname === "/cards" ? "active" : ""}
            >
              CARD GALLERY
            </Link>
            <Link
              to="/stars"
              className={
                location.pathname === "/stars" ||
                location.pathname === "/supporters"
                  ? "active"
                  : ""
              }
            >
              MEET THE STARS
            </Link>
            {/* <Link to="/learn-to-play" className={location.pathname === '/learn-to-play' ? 'active' : ''}>LEARN TO PLAY</Link> */}
            <Link
              to="/contact"
              className={location.pathname === "/contact" ? "active" : ""}
            >
              CONTACT US
            </Link>
            <div
              className={`shop-now-button-mobile-container ${
                !isNavOpen ? "navbar-closed" : "navbar-open"
              }`}
            >
              <Link
                to="https://gamersupps.gg/collections/new-coming-soon"
                className="shop-now-button-mobile"
                style={{ color: "var(--dark-blue-v-card-color)" }}
              >
                SHOP NOW
              </Link>{" "}
              {/* Mobile Shop Now Button */}
            </div>
          </div>
          <div className="shop-now-button-container">
            <Link
              to="https://gamersupps.gg/collections/new-coming-soon"
              className="shop-now-button"
            >
              FREE CARD
            </Link>{" "}
            {/* Desktop Shop Now Button */}
          </div>
        </nav>
      </div>
    </>
  );
};

export default Navbar;
