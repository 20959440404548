import "./css/GameplaySection.css";

const GameplaySection = () => (
  <section className="gameplay-section">
    <div className="gameplay-content section-grid">
      <div className="gameplay-text-box">
        <h2 className="gameplay-title">STRATEGIC GAMEPLAY</h2>
        <img
          src="https://gamersupps.s3.us-east-2.amazonaws.com/Vcards-assets/Small-Divide-Gold_yzlfnx.webp"
          alt="Divider"
          className="divider"
        />
        <p className="gameplay-description">
          Harness dynamic Power Levels and unleash thrilling abilities to
          outsmart and outplay your opponents in this fast paced game of
          strategy and skill! Every move counts when claiming victory in the
          ultimate test of wits and reflexes.
        </p>
      </div>
    </div>
  </section>
);

export default GameplaySection;
