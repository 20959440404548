import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate

import sampleCreatorPlaceholder from "./json/sampleVTuberPlaceholder.jsx";
import CalendarCard from "../../CalendarCard";
import "../../creator/css/CountdownCreators.css";
import { collaboratorAPI } from "../../../../../services/api.js"; // Adjust the path if necessary
import sampleVTuberPlaceholder from "./json/sampleVTuberPlaceholder";

const CountdownCreators = ({ isNavOpen }) => {
  console.log("isNavOpen", isNavOpen);
  const countdownRef = useRef(null);
  const wrapperRef = useRef(null);
  const navigate = useNavigate(); // Initialize useNavigate

  const [creators, setCreators] = useState([]);
  const [flippedCards, setFlippedCards] = useState(
    new Set(JSON.parse(localStorage.getItem("flippedCards23")) || [])
  );
  const VTUBERS_TYPE_NAME = "VTUBERS";
  const [creatorsTypeName, setCreatorsTypeName] = useState(VTUBERS_TYPE_NAME);
  const [isAnyCardFlipped, setIsAnyCardFlipped] = useState(false);
  const [isSwipingGlowActive, setIsSwipingGlowActive] = useState(false); // New state for swiping glow

  useEffect(() => {
    const fetchData = async () => {
      try {
        const type = "VTUBER"; // Use "CREATOR" for creators
        const collaborators = await collaboratorAPI.getCollaborators(type);
        const count = await collaboratorAPI.getCollaboratorCount(type);

        const placeholdersNeeded = count - collaborators.length;

        let placeholders = [];
        if (placeholdersNeeded > 0) {
          placeholders = Array.from(
            { length: placeholdersNeeded },
            (_, index) => ({
              ...sampleVTuberPlaceholder[0],
              id: collaborators.length + index + 1,
              name: "TBD",
            })
          );
        }

        const combinedCreators = [...collaborators, ...placeholders];

        setCreators(combinedCreators);
      } catch (error) {
        console.error("Error fetching creators:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const cardsToFlip = sortedCreators; // Show all creators

    const flipSequentially = (index) => {
      if (
        index < cardsToFlip.length &&
        creatorsTypeName === VTUBERS_TYPE_NAME
      ) {
        const randomDelay = 10 + Math.random() * 5; // Random delay between 100ms and 200ms
        setTimeout(() => {
          setFlippedCards((prev) => {
            const newFlippedCards = new Set(prev).add(cardsToFlip[index].id);
            localStorage.setItem(
              "flippedCards23",
              JSON.stringify(Array.from(newFlippedCards))
            );
            return newFlippedCards;
          });
          flipSequentially(index + 1);
        }, randomDelay);
      } else {
        setIsSwipingGlowActive(false); // Deactivate swiping glow after flipping
      }
    };

    setTimeout(() => {
      if (creatorsTypeName === VTUBERS_TYPE_NAME) {
        setIsSwipingGlowActive(true); // Activate swiping glow before starting the flip
        flipSequentially(0);
      }
    }, 1); // 3-second delay before starting the flip
  }, [creators]);

  useEffect(() => {
    const checkForUnrenderedCards = () => {
      if (countdownRef.current && wrapperRef.current) {
        const containerHeight = countdownRef.current.clientHeight;
        const contentHeight = countdownRef.current.scrollHeight;
        const scrollTop = countdownRef.current.scrollTop;

        if (scrollTop + containerHeight < contentHeight) {
          // Not scrolled to bottom, show glow
          wrapperRef.current.classList.add("has-unrendered-cards");
          wrapperRef.current.style.paddingBottom = "0"; // Remove padding
        } else {
          // Scrolled to bottom, remove glow
          wrapperRef.current.classList.remove("has-unrendered-cards");
          wrapperRef.current.style.paddingBottom = "1rem"; // Add padding
        }
      }
    };

    checkForUnrenderedCards();
    window.addEventListener("resize", checkForUnrenderedCards);
    countdownRef.current.addEventListener("scroll", checkForUnrenderedCards);

    return () => {
      window.removeEventListener("resize", checkForUnrenderedCards);
      if (countdownRef.current) {
        countdownRef.current.removeEventListener(
          "scroll",
          checkForUnrenderedCards
        );
      }
    };
  }, [creators, flippedCards]);

  const updatedCreators = creators.map((creator) => ({
    ...creator,
    isReleased: true, // Temporarily set all creators as released
  }));
  const sortedCreators = updatedCreators.sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  return (
    <div
      ref={wrapperRef}
      className={`countdown-vtubers-wrapper ${creatorsTypeName.toLowerCase()} ${
        isSwipingGlowActive ? "swiping-glow" : ""
      }`}
      style={{ marginTop: "1rem" }}
    >
      <div className="creator-inner-wrapper-for-side-buttons-and-mapped">
        <div className={"inner-creators-cards"}>
          <div className="button-container-countdown-creators">
            <button
              className="creator-type-button"
              style={{
                backgroundColor: "#fbed9f",
                color: "#091a26",
                fontFamily: "Gill Sans Heavy",
              }}
              onClick={() => navigate("/stars")} // Navigate to SupportersPage
            >
              VTUBERS
            </button>
            <button
              className="creator-type-button"
              style={{ fontFamily: "Gill Sans Heavy" }}
              onClick={() => navigate("/supporters")} // Navigate to SupportersPage
            >
              CREATORS
            </button>
          </div>
          <div className="countdown-creators" ref={countdownRef}>
            {sortedCreators.map((creator) => (
              <div key={creator.id} className="calendar-card-container">
                <CalendarCard
                  creator={creator}
                  flipped={flippedCards.has(creator.id)}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CountdownCreators;
