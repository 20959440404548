import React from 'react';
import { useParams } from 'react-router-dom';
import './css/CreatorPage.css';

const CreatorPage = () => {
  let { id } = useParams();
  return (
    <div className="creator-page">
      <h2>Creator: {id}</h2>
      {/* Add creator's card, variants, and social media links here */}
    </div>
  );
};

export default CreatorPage;
