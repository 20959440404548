import "./css/CardRevealSection.css";
const CardRevealsSection = () => (
  <section className="card-reveals-section">
    <div className="section-grid">
      <div className="reveals-header">
        <h2 className="reveals-title">LATEST CARD REVEALS</h2>
        <img
          src="https://gamersupps.s3.us-east-2.amazonaws.com/Vcards-assets/GS-Home-Divide_slfqdj.webp"
          alt="Decorative Divider"
          className="reveals-divider"
          loading="lazy"
        />
      </div>

      <div className="card-reveals-grid">
        {[
          {
            src: "https://gamersupps.s3.us-east-2.amazonaws.com/Vcards-assets/Shylily-04-Web_tltztj.webp",
            alt: "Shylily Card",
          },
          {
            src: "https://gamersupps.s3.us-east-2.amazonaws.com/Vcards-assets/Sinder-04-Web_p7c9mf.webp",
            alt: "Sinder Card",
          },
          {
            src: "https://gamersupps.s3.us-east-2.amazonaws.com/Vcards-assets/Silvervale-04-Web_qwljyz.webp",
            alt: "Silvervale Card",
          },
          {
            src: "https://gamersupps.s3.us-east-2.amazonaws.com/Vcards-assets/Cottontail-04-Web_vgzogc.webp",
            alt: "Cottontailva Card",
          },
        ].map((card, index) => (
          <img
            key={index}
            src={card.src}
            alt={card.alt}
            className="reveal-card"
            loading="lazy"
          />
        ))}
      </div>
    </div>
  </section>
);

export default CardRevealsSection;
