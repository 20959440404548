export const sampleVTuberPlaceholder = [
  {
    id: 0,
    name: "TBD",
    profilePicture:
      "https://gamersupps.s3.us-east-2.amazonaws.com/images/Star-Frames-Empty.png",
    cardImage: "https://placehold.co/225x300",
    packName: "VTUBER",
    releaseDate: "",
    twitter: "",
    youtube: "",
    twitch: "",
    element: "",
  },
];

export default sampleVTuberPlaceholder;
