import React, { useState, useEffect } from "react";
import './RisingStarsPortal.css';

const RisingStarsPortal = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 468);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const backgroundImage = isMobile
        ? "https://gamersupps.s3.us-east-2.amazonaws.com/images/vcard/banners/Home-Banner-Middle-Mobile.png"
        : "https://gamersupps.s3.us-east-2.amazonaws.com/images/vcard/banners/Home-Banner-Middle.png";

    return (
        <div 
            className="RisingStarsPortal"
            style={{ backgroundImage: `url(${backgroundImage})` }}
        >
            <div className="non-image-content-rs">
                <img 
                    src="https://gamersupps.s3.us-east-2.amazonaws.com/images/RS-Logo.png" 
                    className="rising-stars-logo" 
                    alt="risingStar" 
                />
                <p className="RisingStarsDescription" style={{fontFamily: "Gill Sans"}}>
                    EXPLORE THE CARD GALLERY
                </p>
                <a 
                    className="RisingStarsPortalButton" 
                    href="/#/cards"  
                    style={{fontFamily: "Gill Sans Heavy"}}
                >
                    LEARN MORE
                </a>
            </div>
        </div>
    );
}

export default RisingStarsPortal;
